html,
body,
#root,
.pageWrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

}

body {
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  color: #040F0F;
}
h1 {
    font-size: 3em;
    text-align: center;
}

hr {
    width: 75%;
    height:1px;
    border-width:0;
    color:#040F0F;
    background-color:#040F0F;
 }

.pageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  -webkit-transition: background-color 0.5s; /* For Safari 3.1 to 6.0 */
  transition: background-color 0.5s;
}

.card {
  width: 45ch;
  height: 60ch;
  max-width: 70vw;
  max-height: auto;
  background: #f3f3f3;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
    0 2px 2px rgba(0,0,0,0.12), 
    0 4px 4px rgba(0,0,0,0.12), 
    0 8px 8px rgba(0,0,0,0.12),
    0 16px 16px rgba(0,0,0,0.12);
  transition: box-shadow 0.5s;
  will-change: transform;
  border: 15px solid #f3f3f3;
}
.cardWrapper {
    max-width: 80vw;
    max-height: auto;
}
.cardfooter {
    text-align: left;
    position: absolute;
    float: right;
    overflow-wrap: normal;
}
li {
    list-style-type: square;
    margin-left: 1em;
    padding-left: 1em;
    flex-grow: 1;
}
li :hover {
    text-decoration: underline;
}
.innards {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.link {
    text-decoration: none;
    font-weight: bold;
    font-size: 2em;
    color: #040F0F;
}
.card:hover {
  box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}
ul {
    margin-bottom: auto;
}
.bottomIcons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-content: center;
    position: absolute;
    bottom: 2ch;
    

}
.iconContainerLink {
    text-decoration: none;
}
.iconContainerLink :hover {
    text-decoration: underline;
}
.iconText {
    text-align: center;
    color: #040F0F;
    font-weight: bold;
    margin: 0.5em;
}
.fab, .fas {
    color: #040F0F;
}
.bottomIcons :hover {
    text-decoration: underline ;
}
.iconContainerLink {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contactButton {
    display: hidden;
}