body {
    background-color: #3d7b94;
    -webkit-transition: background-color 0.5s; /* For Safari 3.1 to 6.0 */
    transition: background-color 0.5s;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.2;
}
#contactHomeButton{
    color: #040f0f;
}
.toMusicLink {
    color: #f3f3f3;
    font-weight: bold;
}
.pageH1 {
    padding: 0;
    margin: 0;
    color: #f3f3f3;
    font-size: 5em;
    text-align: left;
}
.pageP {
    color: #f3f3f3;
    font-size: 1.5em;
    font-weight: bold;
    text-align: justify;
}
.aboutP {
    color: #f3f3f3;
    text-align: justify;
}

.email {
    text-decoration: none;
    font-weight: bold;
    color: #f3f3f3;
}
.email:hover { 
    text-decoration: underline;
    text-decoration-color: #1f58cc;
    
}
.pageOuterContainer {
    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    max-width: 1000px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}
.designContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.designImage {
    margin-bottom: 4vh;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
    0 2px 2px rgba(0,0,0,0.12), 
    0 4px 4px rgba(0,0,0,0.12), 
    0 8px 8px rgba(0,0,0,0.12),
    0 16px 16px rgba(0,0,0,0.12);
      
}
.designImage :hover {
    box-shadow: 0px 30px 10px -10px rgba(0, 0, 0, 0.4);
}
.musicContainer {
    display: flex;
    flex-direction: row;
    color: #f3f3f3;
    justify-content: center;
    margin-top: 3vh;
    margin-bottom: 3vh;
}
.musicIcon {
    color: #f3f3f3;
}
.musicIconLink {
    margin: 3vh;
}
.homeButton {
    color: #f3f3f3;
    align-self: center;
    position: fixed;
}
.backHome {
    padding-left: 1vh;
    padding-top: 3vh;
    margin: 3vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: fixed;
    color: #f3f3f3;
}
.backHomeText {
    margin: 0.5em;
}
.contact-form {
    display: flex;
    flex-direction: column;

}
.designOuterContainer {
    color: #040f0f;
    font-weight: bold;
}
#contactH1 {
    color: #040f0f;
}
#contactPageP {
    color: #040f0f;
}
.contactFormMember{
    margin: 1vh;
    padding: 1vh;
    border-radius: 1px;
}
.contactFormInput, .contactFormInput :-webkit-autofill, .contactFormInput :focus {
    font: 400 13.3333px Arial;
    border: none;
    font-weight: bold;
    box-shadow: 0 8px 8px rgba(0,0,0,0.03);
}
#contactHomeButton{
    color: #040f0f !important;
}