body {
    background-color: #f0483c;
    -webkit-transition: background-color 0.5s; /* For Safari 3.1 to 6.0 */
    transition: background-color 0.5s;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.toCodeLink {
    color: #f3f3f3;
    font-weight: bold;
}
.pageH1 {
    padding: 0;
    margin: 0;
    color: #f3f3f3;
    font-size: 5em;
    text-align: left;
}
.pageP {
    color: #f3f3f3;
    font-size: 1.5em;
    font-weight: bold;
    text-align: justify;
}
.aboutP {
    color: #f3f3f3;
    text-align: justify;
}

.email {
    text-decoration: none;
    font-weight: bold;
    color: #f3f3f3;
}
.email:hover { 
    text-decoration: underline;
    text-decoration-color: #1f58cc;
    
}
.pageOuterContainer {
    padding-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    max-width: 1000px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}
.homeButton {
    color: #f3f3f3;
    align-self: center;
    position: fixed;
}
.backHome {
    padding-left: 1vh;
    padding-top: 3vh;
    margin: 3vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: fixed;
    color: #f3f3f3;
}
.backHomeText {
    margin: 0.5em;
}
