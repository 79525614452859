body {
/* a04eed*/
    background-color: #a04eed;
    -webkit-transition: background-color 0.5s; /* For Safari 3.1 to 6.0 */
    transition: background-color 0.5s;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.pageH1 {
    color: #f3f3f3;
    font-size: 5em;
    text-align: left;
}
.pageP {
    color: #f3f3f3;
    font-size: 1.5em;
    font-weight: bold;
    text-align: justify;
}
.aboutP {
    color: #f3f3f3;
    text-align: justify;
}
.timeAndTechP {
    color: #f3f3f3;
    font-weight: bold;
}
.techStackP {
    font-style: italic;
}
.email {
    text-decoration: none;
    font-weight: bold;
    color: #f3f3f3;
}
.email:hover { 
    text-decoration: underline;
    text-decoration-color: #1f58cc;
    
}
.pageOuterContainer {
    padding-left: 5vw;
    padding-right: 5vw;
    max-width: 1000px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}
.projectImage {
    
    min-height: 20vh;
    max-height: 20vh;
    width: auto;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12),
    0 2px 2px rgba(0,0,0,0.12), 
    0 4px 4px rgba(0,0,0,0.12), 
    0 8px 8px rgba(0,0,0,0.12),
    0 16px 16px rgba(0,0,0,0.12);
      
}
.projectImage :hover {
    box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}
.imgContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: space-between;
    flex-wrap: wrap;
}
.toDesignLink {
    color: #f3f3f3;
    font-weight: bold;
}
.homeButton {
    color: #f3f3f3;
    align-self: center;
    position: fixed;
}
.backHome {
    padding-left: 1vh;
    padding-top: 3vh;
    margin: 3vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: fixed;
    color: #f3f3f3;
}
.backHomeText {
    margin: 0.5em;
}
